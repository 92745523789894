import React, { forwardRef, useImperativeHandle, useState } from 'react'
import VerificationDialog from 'src/components/auth/verificationDialog'
import LoginForm from 'src/components/auth/loginFormDialog'
import ForgetPassword from 'src/components/auth/forgetPasswordDialog'
import OtpDialog from 'src/components/auth/otpDialog'
import ResetPassword from 'src/components/auth/resetpassword'
import RegistrationForm from 'src/components/auth/registrationFromDialog'

const DailogAuth = forwardRef((props, ref) => {
  const [selectedEmail, setSelectedEmail] = useState('')
  const [openVerificationDialog, setOpenVerificationDialog] = useState(false)
  const [openLoginDialog, setOpenLoginDialog] = useState(false)
  const [openRegistrationDialog, setOpenRegistrationDialog] = useState(false)
  const [openFrogetPassDialog, setOpenFrogetPassDialog] = useState(false)
  const [openOtpDialog, setOpenOtpDialog] = useState(false)
  const [openResetPasswordDialog, setOpenResetPasswordDialog] = useState(false)

  const handleVerificationSuccess = email => {
    setSelectedEmail(email)
    setOpenVerificationDialog(false) // Close the VerificationDialog
    setOpenLoginDialog(true) // Open the LoginForm
  }
  const handleRegistration = email => {
    setSelectedEmail(email)
    setOpenVerificationDialog(false)
    setOpenRegistrationDialog(true)
  }

  const handleForgetPassword = email => {
    setSelectedEmail(email)
    setOpenLoginDialog(false) // Close the LoginForm
    setOpenFrogetPassDialog(true) // Open the ForgetPassword dialog
  }

  const handleOtpDailog = email => {
    setSelectedEmail(email)
    setOpenFrogetPassDialog(false)
    setOpenLoginDialog(false)
    console.log('Closing ForgotDialog')
    setOpenOtpDialog(true) // Open the Otp dialog
    console.log('Open OtpDialog')
  }

  const handleResetPassword = email => {
    console.log('ResetEmail', email)
    setSelectedEmail(email)
    setOpenOtpDialog(false) // Close the Otp
    console.log('Closing OtpDialog')
    setOpenResetPasswordDialog(true) // Open the Reset dialog
    console.log('Open ResetDaiolog')
  }
  const handleVerifyOtp = () => {
    setOpenOtpDialog(false)
    setOpenLoginDialog(true)
  }

  const openLogin = () => {
    setOpenVerificationDialog(true)
  }
  const openRegistartion = () => {
    setOpenRegistrationDialog(true)
  }

  useImperativeHandle(
    ref,
    () => {
      return {
        openLogin,
        openRegistartion
      }
    },
    []
  )

  return (
    <>
      <VerificationDialog
        open={openVerificationDialog}
        toggle={() => setOpenVerificationDialog(false)}
        openLogin={handleVerificationSuccess}
        openRegistrationDialog={handleRegistration}
      />
      <LoginForm
        open={openLoginDialog}
        toggle={() => setOpenLoginDialog(false)}
        email={selectedEmail}
        openForgetPassword={handleForgetPassword}
        openOtp={handleOtpDailog}
      />
      <RegistrationForm
        open={openRegistrationDialog}
        toggle={() => setOpenRegistrationDialog(false)}
        email={selectedEmail}
        openOtp={handleOtpDailog}
      />

      <ForgetPassword
        open={openFrogetPassDialog}
        toggle={() => setOpenFrogetPassDialog(false)}
        email={selectedEmail}
        openOtp={handleOtpDailog}
      />
      <OtpDialog
        open={openOtpDialog}
        toggle={() => setOpenOtpDialog(false)}
        email={selectedEmail}
        openResetPassword={handleResetPassword}
        openLogin={handleVerifyOtp}
      />
      <ResetPassword
        open={openResetPasswordDialog}
        toggle={() => setOpenResetPasswordDialog(false)}
        email={selectedEmail}
      />
    </>
  )
})

export default DailogAuth
