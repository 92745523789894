const ArrowDown = ({ size, fill }) => {
  return (
    <svg width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clip-path='url(#clip0_101_2)'>
        <path
          d='M16.25 8.27783L10 14.5278L3.75 8.27783'
          stroke={fill}
          stroke-width='2'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_101_2'>
          <rect width='20' height='20' fill={fill} transform='translate(0 0.777832)' />
        </clipPath>
      </defs>
    </svg>
  )
}
export default ArrowDown
