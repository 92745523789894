// ** React Imports
import { useEffect } from 'react'

// ** Next Imports
import { useNavigate, useLocation } from 'react-router-dom'

// ** Hooks Import
import { useAuth } from 'src/hooks/useAuth'

// ** Config
import authConfig from 'src/configs/auth'

const AuthGuard = props => {
  const { children, fallback } = props
  const auth = useAuth()

  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (auth.user === null && !window.localStorage.getItem(authConfig.storageUserDataKeyName)) {
      const currentPath = location.pathname + location.search + location.hash
      navigate(`/landing-page?redirect=${currentPath}`)
    }
  }, [auth.user, auth.loading, location])

  if (auth.loading || auth.user === null) {
    return fallback
  }

  return children
}

export default AuthGuard
