import { useState, useCallback, useEffect } from 'react'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import FormLabel from '@mui/material/FormLabel'
import { styled } from '@mui/material/styles'
import useCustomTranslation from 'src/@core/hooks/useCustomTranslation'
import { useForm, Controller } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { axiosInstance } from 'src/network/adapter'
import { ApiEndPoints } from 'src/network/endpoints'
import { toastError, toastSuccess } from 'src/utils/utils'
import { LoadingButton } from '@mui/lab'

const TitleTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontSize: '32px',
  lineHeight: '44.8px',
  fontWeight: 700,
  textAlign: 'center'
}))

const validationSchema = yup.object().shape({
  email: yup.string().email('Invalid email').required('Email is required')
})

function ForgetPassword({ open, toggle, email: initialEmail, openOtp }) {
  const [loading, setLoading] = useState(false)
  const translation = useCustomTranslation()

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange'
  })

  useEffect(() => {
    if (initialEmail) {
      setValue('email', initialEmail)
    }
  }, [initialEmail, setValue])

  const handleNextClick = useCallback(
    data => {
      setLoading(true)
      const emailToSend = data.email

      axiosInstance
        .post(ApiEndPoints.AUTH.forgetpassword, { email: emailToSend })
        .then(response => {
          toastSuccess(response.data.message)
          toggle()
          openOtp(emailToSend) // This will open the OTP dialog
        })
        .catch(error => {
          toastError(error)
        })
        .finally(() => {
          setLoading(false)
        })
    },
    [toggle, openOtp]
  )

  return (
    <>
      <Dialog
        open={open}
        onClose={toggle}
        maxWidth='sm'
        fullWidth
        sx={{
          '& .MuiDialog-paper': {
            backgroundColor: 'transparent',
            borderRadius: '30px',
            overflow: 'hidden'
          }
        }}
      >
        <Grid container sx={{ overflowY: { xs: 'auto', xl: 'hidden' } }}>
          <Grid
            item
            sx={{
              display: 'flex',
              alignItems: 'center',
              backgroundColor: '#FFFFFF',
              p: { md: 10, xs: '24px 12px' },
              width: '100%'
            }}
          >
            <Container>
              <Box display='flex' flexDirection='column' textAlign='center' gap={2} paddingBottom={'20px'}>
                <TitleTypography>Forget Password?</TitleTypography>
                <Typography>
                  To reset your password, enter your email address and we will send you a link to reset it.
                </Typography>
              </Box>
              <Box>
                <Box display='flex' flexDirection='column' gap='20px'>
                  <form noValidate autoComplete='off' onSubmit={handleSubmit(handleNextClick)}>
                    <FormControl fullWidth sx={{ mb: 4 }}>
                      <FormLabel htmlFor='email' sx={{ color: '#253547', fontSize: '16px' }}>
                        Email
                      </FormLabel>
                      <Controller
                        name='email'
                        control={control}
                        render={({ field: { value, onChange } }) => (
                          <TextField
                            autoFocus
                            id='email'
                            placeholder='Enter Email'
                            value={value}
                            onChange={onChange}
                            error={!!errors.email}
                            helperText={errors.email ? errors.email.message : ''}
                          />
                        )}
                      />
                    </FormControl>
                    <Box display='flex' gap={6} mt={4}>
                      <Button
                        onClick={toggle}
                        variant='outlined'
                        fullWidth
                        sx={{ padding: '14px 18px', border: '1px solid #0D1E32', fontSize: '18px', color: '#0D1E32' }}
                      >
                        Cancel
                      </Button>
                      <LoadingButton
                        type='submit'
                        variant='contained'
                        fullWidth
                        loading={loading}
                        sx={{ padding: '14px 18px', border: '1px solid #0D1E32', fontSize: '18px' }}
                      >
                        Next
                      </LoadingButton>
                    </Box>
                  </form>
                </Box>
              </Box>
            </Container>
          </Grid>
        </Grid>
      </Dialog>
    </>
  )
}

export default ForgetPassword
