const NotificationIcon = ({ fill, size = 21 }) => {
  return (
    <svg width={size} height={size} viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clip-path='url(#clip0_495_1195)'>
        <path
          d='M7.5 15.7778C7.5 16.4409 7.76339 17.0768 8.23223 17.5456C8.70107 18.0144 9.33696 18.2778 10 18.2778C10.663 18.2778 11.2989 18.0144 11.7678 17.5456C12.2366 17.0768 12.5 16.4409 12.5 15.7778'
          stroke={fill}
          stroke-width='1.25'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M14.375 2.65283C15.5819 3.42292 16.5608 4.5019 17.2102 5.77783'
          stroke={fill}
          stroke-width='1.25'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M2.78984 5.77783C3.43919 4.5019 4.41809 3.42292 5.625 2.65283'
          stroke={fill}
          stroke-width='1.25'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M4.37502 9.52783C4.37502 8.03599 4.96765 6.60525 6.02254 5.55036C7.07743 4.49546 8.50817 3.90283 10 3.90283C11.4919 3.90283 12.9226 4.49546 13.9775 5.55036C15.0324 6.60525 15.625 8.03599 15.625 9.52783C15.625 12.3263 16.2735 13.9497 16.7891 14.8403C16.8438 14.9352 16.8727 15.0428 16.8728 15.1523C16.8729 15.2618 16.8442 15.3694 16.7896 15.4644C16.735 15.5593 16.6565 15.6383 16.5617 15.6933C16.467 15.7483 16.3595 15.7774 16.25 15.7778H3.75002C3.64063 15.7772 3.53332 15.7478 3.43883 15.6927C3.34435 15.6376 3.26598 15.5586 3.21157 15.4637C3.15716 15.3688 3.12861 15.2613 3.12878 15.1519C3.12894 15.0425 3.15782 14.9351 3.21252 14.8403C3.72736 13.9497 4.37502 12.3255 4.37502 9.52783Z'
          stroke={fill}
          stroke-width='1.25'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_495_1195'>
          <rect width='20' height='20' fill='white' transform='translate(0 0.777832)' />
        </clipPath>
      </defs>
    </svg>
  )
}
export default NotificationIcon
