// export const API_BASE_URL = 'http://localhost:8000'
// export const API_BASE_URL = 'http://3.141.192.236:8000'
export const API_BASE_URL = 'https://api.cruizewatersport.ai'

export const MEDIA_URL = 'https://cruize-data-storage.s3.us-east-2.amazonaws.com'

export const ApiEndPoints = {
  AUTH: {
    me: `${API_BASE_URL}/api/v1/auth/user/profile`,
    verifyEmail: `${API_BASE_URL}/api/v1/auth/user/verifyEmail`,
    login: `${API_BASE_URL}/api/v1/auth/user/login`,
    register: `${API_BASE_URL}/api/v1/auth/user/register`,
    forgetpassword: `${API_BASE_URL}/api/v1/auth/user/forgot-password`,
    verifyOtp: `${API_BASE_URL}/api/v1/auth/user/otp-verification`,
    resetPassword: `${API_BASE_URL}/api/v1/auth/user/reset-password`,
    resendOtp: `${API_BASE_URL}/api/v1/auth/user/resend-otp`,
    socialLogin: `${API_BASE_URL}/api/v1/auth/user/social-login`
  },
  DASHBOARD: {
    count: `${API_BASE_URL}/api/v1/settings/dashboard`
  },
  PROPERTY: {
    get: `${API_BASE_URL}/api/v1/admin/property/getAllProperty`,
    getById: id => `${API_BASE_URL}/api/v1/admin/property/getProperty/${id}`
  },
  AVAILABILITY: {
    getAvailability: id => `${API_BASE_URL}/api/v1/auth/${id}`
  },
  BOOKINGS: {
    getBookings: `${API_BASE_URL}/api/v1/auth/bookings`,
    createBookings: `${API_BASE_URL}/api/v1/auth/bookings`
  }
}
