import { useState } from 'react'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import AuthDialogSideBoat from 'src/assets/images/authDialogSideBoat.png'
import LeftArrowBlack from 'src/assets/images/leftArrowBlack.svg'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import Divider from '@mui/material/Divider'
import Container from '@mui/material/Container'
import useCustomTranslation from 'src/@core/hooks/useCustomTranslation'
import GoogleIcon from 'src/assets/images/Google.svg'
import FaceBookIcon from 'src/assets/images/Facebook.svg'
import FormLabel from '@mui/material/FormLabel'
import { Grid } from '@mui/material'
import { axiosInstance } from 'src/network/adapter'
import { ApiEndPoints } from 'src/network/endpoints'
import { toastError } from 'src/utils/utils'
import { useForm, Controller } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useGoogleLogin } from '@react-oauth/google'
import { useAuth } from 'src/hooks/useAuth'

const ImageGrid = styled(Grid)(({ theme }) => ({
  backgroundImage: `url(${AuthDialogSideBoat})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  height: '348px',
  [theme.breakpoints.up('sm')]: {
    height: '90vh'
  }
}))

const ContentGrid = styled(Grid)(({ theme }) => ({
  backgroundColor: '#FFFFFF',
  display: 'flex',
  alignItems: 'center',
  padding: '24px 12px'
}))

const BackButton = styled(Button)(({ theme }) => ({
  padding: '10px 20px',
  backgroundColor: '#FFFFFF',
  border: '1px solid #868F99',
  position: 'sticky',
  top: '25px',
  left: '25px'
}))

const TitleTypography = styled(Box)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontSize: '32px',
  lineHeight: '44.8px',
  fontWeight: 700,
  textAlign: 'center'
}))

const FaceBookButton = styled(Button)(({ theme }) => ({
  color: theme.palette.secondary.main,
  border: '1px solid #CCCCCC',
  borderRadius: '61px',
  padding: '16px'
}))

const GoogleButton = styled(Button)(({ theme }) => ({
  color: theme.palette.secondary.main,
  border: '1px solid #CCCCCC',
  borderRadius: '61px',
  padding: '16px'
}))

const validationSchema = yup.object().shape({
  email: yup.string().email('Invalid email format').required('Email is required')
})

function VerificationDialog({ open, toggle, openLogin, openRegistrationDialog }) {
  const auth = useAuth()
  const translation = useCustomTranslation()
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [error, setError] = useState('')

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange'
  })

  const onSubmit = async data => {
    setLoading(true)
    setError('')
    setEmail(data.email)
    try {
      const response = await axiosInstance.post(ApiEndPoints.AUTH.verifyEmail, { email: data.email })
      if (response.data && response.data.user === true) {
        toggle()
        reset()
        openLogin(data.email)
      } else {
        openRegistrationDialog(data.email)
        toggle()
      }
    } catch (error) {
      setError('An error occurred while checking the email.')
      toastError(error.message || 'An error occurred while checking the email.')
    } finally {
      setLoading(false)
    }
  }

  const handleGoogleLoginSuccess = async tokenResponse => {
    const token = tokenResponse.access_token

    try {
      const payload = { clientId: '77297453844-55p8pk3r6igggtdnm38vfebc1o67dk6f.apps.googleusercontent.com', token }
      console.log('payload:', payload)

      const apiResponse = await axiosInstance.post(ApiEndPoints.AUTH.socialLogin, payload)
      if (apiResponse.data.status === 'success') {
        const user = apiResponse.data.user
        auth.login({ user, token })
        toggle()
      } else {
        toastError(apiResponse.data.message)
      }
    } catch (error) {
      toastError('Failed to login with Google. Please try again.')
    }
  }

  const handleGoogleLoginError = error => {
    toastError('Google login failed. Please try again.')
  }

  const googleLogin = useGoogleLogin({
    onSuccess: handleGoogleLoginSuccess,
    onError: handleGoogleLoginError
  })

  const handleBackButtonClick = () => {
    setError('')
    reset()
    toggle()
  }

  return (
    <Dialog
      open={open}
      onClose={handleBackButtonClick}
      maxWidth='lg'
      fullWidth
      sx={{
        '& .MuiDialog-paper': {
          backgroundColor: 'transparent',
          borderRadius: '30px'
        }
      }}
    >
      <Grid container>
        <ImageGrid item xs={12} sm={6}>
          <BackButton onClick={handleBackButtonClick}>
            <img src={LeftArrowBlack} alt='Back Icon' />
          </BackButton>
        </ImageGrid>
        <ContentGrid item xs={12} sm={6} p={{ md: 10, xs: 3 }} overflow={'auto'}>
          <Container>
            <Box paddingBottom={'20px'}>
              <TitleTypography>Welcome to</TitleTypography>
              <TitleTypography>Cruize watersport</TitleTypography>
            </Box>
            <Box display={'flex'} flexDirection={'column'} gap={'32px'}>
              <Box display={'flex'} flexDirection={'column'} gap={'20px'}>
                <form noValidate autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
                  <FormControl fullWidth sx={{ mb: 4 }}>
                    <FormLabel htmlFor='email' sx={{ color: '#253547', fontSize: '16px' }}>
                      Email
                    </FormLabel>
                    <Controller
                      name='email'
                      control={control}
                      render={({ field }) => (
                        <TextField
                          autoFocus
                          id='email'
                          placeholder={'Enter Email'}
                          {...field}
                          error={!!errors.email}
                          helperText={errors.email ? errors.email.message : ''}
                        />
                      )}
                    />
                  </FormControl>
                  <Button
                    variant='contained'
                    fullWidth
                    sx={{ padding: '19px 24px', border: '1px solid #0D1E32', color: '#0D1E32', fontSize: '18px' }}
                    type='submit'
                    disabled={loading}
                  >
                    {loading ? 'Loading...' : 'Continue'}
                  </Button>
                </form>
              </Box>
              <Box display={'flex'} flexDirection={'column'} gap={'12px'}>
                <Divider style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <span style={{ marginRight: '10px' }}> </span>
                  OR
                  <span style={{ marginLeft: '10px' }}> </span>
                </Divider>
                <GoogleButton
                  variant='outlined'
                  startIcon={<img src={GoogleIcon} alt='google icon' />}
                  onClick={() => googleLogin()}
                >
                  Log in with Google
                </GoogleButton>
                <FaceBookButton variant='outlined' startIcon={<img src={FaceBookIcon} alt='facebook icon' />}>
                  Log in with Facebook
                </FaceBookButton>
              </Box>
            </Box>
          </Container>
        </ContentGrid>
      </Grid>
    </Dialog>
  )
}

export default VerificationDialog
