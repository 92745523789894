import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import PublicLayoutAppBar from './components/publickLayoutAppbar'
import LandingPageFooter from 'src/components/landingPage/footer'

const PublicLayoutWrapper = styled(Box)(({ theme }) => ({}))

const PublicLayout = props => {
  const { children } = props
  return (
    <PublicLayoutWrapper>
      <PublicLayoutAppBar />
      <Box
        sx={{
          overflowX: 'hidden',
          position: 'relative',
          backgroundColor: '#E3EDED',
          minHeight: theme => `calc(100vh - ${theme.spacing(theme.mixins.toolbar.minHeight / 4)})`
        }}
      >
        {children}
      </Box>
      <LandingPageFooter />
    </PublicLayoutWrapper>
  )
}
export default PublicLayout
