import { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import AuthDialogSideBoat from 'src/assets/images/authDialogSideBoat.png'
import LeftArrowBlack from 'src/assets/images/leftArrowBlack.svg'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import Container from '@mui/material/Container'
import useCustomTranslation from 'src/@core/hooks/useCustomTranslation'
import FormLabel from '@mui/material/FormLabel'
import { Typography, IconButton, InputAdornment, Grid } from '@mui/material'
import LoginForm from './loginFormDialog'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/bootstrap.css'
import { useForm, Controller } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { LoadingButton } from '@mui/lab'
import { axiosInstance } from 'src/network/adapter'
import { ApiEndPoints } from 'src/network/endpoints'
import { toastError, toastSuccess } from 'src/utils/utils'
import { FormValidationMessages } from 'src/constants/form.const'
import { useNavigate } from 'react-router-dom'

// Define styled components
const ImageWrapper = styled(Grid)(({ theme }) => ({
  backgroundImage: `url(${AuthDialogSideBoat})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  height: '348px',
  position: 'sticky',
  filter: 'contrast(1.1) brightness(1.0)',
  [theme.breakpoints.up('md')]: {
    height: '100%'
  }
}))

const BackButton = styled(Button)(({ theme }) => ({
  padding: '10px 20px',
  backgroundColor: '#FFFFFF',
  border: '1px solid #868F99',
  position: 'sticky',
  top: '25px',
  left: '25px'
}))

const TitleTypography = styled(Box)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontSize: '32px',
  lineHeight: '44.8px',
  fontWeight: 700,
  textAlign: 'center'
}))

const validationSchema = yup.object().shape({
  email: yup.string().email(FormValidationMessages.EMAIL.invalid).required(FormValidationMessages.EMAIL.required),
  firstName: yup.string().required(FormValidationMessages.FIRST_NAME.required),
  lastName: yup.string().required(FormValidationMessages.LAST_NAME.required),
  mobile: yup.string().required(FormValidationMessages.Mobile_No.required),
  password: yup
    .string()
    .min(FormValidationMessages.PASSWORD.minLength, FormValidationMessages.PASSWORD.minLengthErrorMessage)
    .matches(FormValidationMessages.PASSWORD.pattern, FormValidationMessages.PASSWORD.patternErrorMessage)
    .required(FormValidationMessages.PASSWORD.required)
})

function RegistrationForm({ open, toggle, email, openOtp }) {
  const navigate = useNavigate()
  const [openLoginDialog, setOpenLoginDialog] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [loading, setLoading] = useState(false)
  const [storedEmail, setStoredEmail] = useState(email)
  const translation = useCustomTranslation()

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    getValues
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    defaultValues: {
      email: email
    }
  })

  useEffect(() => {
    setStoredEmail(email)
    setValue('email', email)
  }, [email, setValue])

  useEffect(() => {
    setStoredEmail(getValues('email'))
  }, [getValues('email')])

  const handleLoginClose = () => {
    setOpenLoginDialog(prev => !prev)
  }

  const handleLoginClick = () => {
    toggle()
    handleLoginClose()
  }

  const handleTogglePasswordVisibility = () => {
    setShowPassword(prev => !prev)
  }

  const onSubmit = data => {
    setLoading(true)
    const payload = {
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      phone: data.mobile,
      password: data.password
    }

    axiosInstance
      .post(ApiEndPoints.AUTH.register, payload)
      .then(response => response.data)
      .then(response => {
        toastSuccess(response.message)
        setLoading(false)
        openOtp(data.email)
        toggle()
        reset()
      })
      .catch(error => {
        console.log('error: ', error)
        toastError(error)
        setLoading(false)
      })
  }

  const handleBackButtonClick = () => {
    reset()
    toggle()
  }

  const handleClick = () => {
    toggle()
    setTimeout(() => {
      navigate('/terms&conditions')
    }, 0)
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleBackButtonClick}
        maxWidth='lg'
        fullWidth
        sx={{
          '& .MuiDialog-paper': {
            backgroundColor: 'transparent',
            borderRadius: '30px'
            // overflow: 'hidden'
          }
        }}
      >
        <Grid container sx={{ overflowY: 'auto' }}>
          <Grid item xs={12} sm={6}>
            <ImageWrapper>
              <BackButton onClick={handleBackButtonClick}>
                <img src={LeftArrowBlack} alt='Back Icon' />
              </BackButton>
            </ImageWrapper>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{ display: 'flex', alignItems: 'center', backgroundColor: '#FFFFFF', p: { md: 10, xs: 3 } }}
          >
            <Container>
              <Box paddingBottom={'20px'}>
                <TitleTypography>Sign Up</TitleTypography>
              </Box>
              <Box display={'flex'} flexDirection={'column'} gap={'24px'}>
                <form noValidate autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
                  <Box display={'flex'} flexDirection={'column'} gap={'10px'}>
                    <FormControl fullWidth>
                      <FormLabel htmlFor='email' sx={{ color: '#253547', fontSize: '16px' }}>
                        {translation.translate('form.email.label')}
                      </FormLabel>
                      <Controller
                        name='email'
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            autoFocus
                            id='email'
                            placeholder={translation.translate('form.email.placeholder')}
                            error={!!errors.email}
                            helperText={
                              errors.email ? translation.translate(`form.email.error.${errors.email.message}`) : ''
                            }
                          />
                        )}
                      />
                      <Box display='flex' mt={2}>
                        <Typography sx={{ color: '#0D1E32', fontSize: '16px' }}>Already have an account?</Typography>
                        <Typography
                          variant='body2'
                          onClick={handleLoginClick}
                          sx={{
                            textDecoration: 'underline',
                            cursor: 'pointer',
                            pl: '5px',
                            color: '#0D1E32',
                            fontSize: '16px'
                          }}
                        >
                          Log in
                        </Typography>
                      </Box>
                    </FormControl>
                    <FormControl fullWidth>
                      <FormLabel htmlFor='fname' sx={{ color: '#253547', fontSize: '16px' }}>
                        {translation.translate('form.firstName.label')}
                      </FormLabel>
                      <Controller
                        name='firstName'
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            id='fname'
                            placeholder={translation.translate('form.firstName.placeholder')}
                            error={!!errors.firstName}
                            helperText={errors.firstName ? errors.firstName.message : ''}
                          />
                        )}
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <FormLabel htmlFor='lname' sx={{ color: '#253547', fontSize: '16px' }}>
                        {translation.translate('form.lastName.label')}
                      </FormLabel>
                      <Controller
                        name='lastName'
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            id='lname'
                            placeholder={translation.translate('form.lastName.placeholder')}
                            error={!!errors.lastName}
                            helperText={errors.lastName ? errors.lastName.message : ''}
                          />
                        )}
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <FormLabel htmlFor='mobile' sx={{ color: '#253547', fontSize: '16px' }}>
                        {translation.translate('form.mobile.label')}
                      </FormLabel>
                      <Controller
                        name='mobile'
                        control={control}
                        render={({ field: { value, onChange } }) => (
                          <>
                            <PhoneInput
                              inputStyle={{
                                width: '100%',
                                borderRadius: '30px',
                                borderColor: errors.mobile ? 'red' : undefined
                              }}
                              country={'in'}
                              value={value}
                              onChange={onChange}
                            />
                            {errors.mobile && (
                              <Typography variant='fm-p4' color='error' mt={1}>
                                {errors.mobile.message}
                              </Typography>
                            )}
                          </>
                        )}
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <FormLabel htmlFor='password' sx={{ color: '#253547', fontSize: '16px' }}>
                        {translation.translate('form.password.label')}
                      </FormLabel>
                      <Controller
                        name='password'
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            id='password'
                            type={showPassword ? 'text' : 'password'}
                            placeholder={translation.translate('form.password.placeholder')}
                            error={!!errors.password}
                            helperText={errors.password ? errors.password.message : ''}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position='end'>
                                  <IconButton onClick={handleTogglePasswordVisibility}>
                                    {showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                                  </IconButton>
                                </InputAdornment>
                              )
                            }}
                          />
                        )}
                      />
                      <Box display='flex' flexDirection={{ md: 'row', xs: 'column' }} mt={2} pb={3}>
                        <Typography sx={{ color: '#0D1E32', fontSize: '16px' }}>
                          By clicking on register, you agree to our
                        </Typography>
                        <Typography
                          variant='body2'
                          onClick={handleClick}
                          sx={{
                            textDecoration: 'underline',
                            cursor: 'pointer',
                            color: '#0D1E32',
                            fontSize: '16px',
                            pl: 1
                          }}
                        >
                          Terms & Conditions.
                        </Typography>
                      </Box>
                    </FormControl>
                    <LoadingButton
                      variant='contained'
                      fullWidth
                      sx={{ padding: '14px 18px', border: '1px solid #0D1E32', fontSize: '18px' }}
                      type='submit'
                      loading={loading}
                    >
                      Sign up
                    </LoadingButton>
                  </Box>
                </form>
              </Box>
            </Container>
          </Grid>
        </Grid>
      </Dialog>
      <LoginForm open={openLoginDialog} toggle={handleLoginClose} />
    </>
  )
}

export default RegistrationForm
