const EarthIcon = ({ size, fill = "#B5D9D7" }) => {
  return (
    <svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clip-path='url(#clip0_33_61)'>
        <path
          d='M12 21.7778C16.9706 21.7778 21 17.7484 21 12.7778C21 7.80727 16.9706 3.77783 12 3.77783C7.02944 3.77783 3 7.80727 3 12.7778C3 17.7484 7.02944 21.7778 12 21.7778Z'
          stroke={fill}
          stroke-width='1.5'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M15.75 12.7778C15.75 18.7778 12 21.7778 12 21.7778C12 21.7778 8.25 18.7778 8.25 12.7778C8.25 6.77783 12 3.77783 12 3.77783C12 3.77783 15.75 6.77783 15.75 12.7778Z'
          stroke={fill}
          stroke-width='1.5'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M3.51187 9.77783H20.4881'
          stroke={fill}
          stroke-width='1.5'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M3.51187 15.7778H20.4881'
          stroke={fill}
          stroke-width='1.5'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_33_61'>
          <rect width='24' height='24' fill='white' transform='translate(0 0.777832)' />
        </clipPath>
      </defs>
    </svg>
  )
}
export default EarthIcon
