import { useState, useCallback } from 'react'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import Container from '@mui/material/Container'
import useCustomTranslation from 'src/@core/hooks/useCustomTranslation'
import FormLabel from '@mui/material/FormLabel'
import { Typography, Grid } from '@mui/material'
import { useForm, Controller } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { axiosInstance } from 'src/network/adapter'
import { ApiEndPoints } from 'src/network/endpoints'
import { toastError, toastSuccess } from 'src/utils/utils'
import { LoadingButton } from '@mui/lab'

const TitleTypography = styled(Box)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontSize: '32px',
  lineHeight: '44.8px',
  fontWeight: 700,
  textAlign: 'center'
}))

const validationSchema = yup.object().shape({
  otp: yup.string().required('OTP is required')
})

function OtpDialog({ open, toggle, email, openResetPassword, openLogin }) {
  const [loading, setLoading] = useState(false)
  const translation = useCustomTranslation()

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange'
  })

  const handleOtpSubmit = useCallback(
    async data => {
      setLoading(true)
      try {
        const response = await axiosInstance.post(ApiEndPoints.AUTH.verifyOtp, { email, otp: data.otp })
        const result = response.data
        console.log('resultDATA', result)

        if (result.otpType === 'register') {
          toastSuccess(result.message)
          toggle()
          openLogin(true)
          reset()
        } else if (result.otpType === 'forgot') {
          toastSuccess(result.message)
          toggle()
          openResetPassword(email)
          reset()
        }
      } catch (error) {
        toastError(error)
      } finally {
        setLoading(false)
      }
    },
    [email, toggle]
  )

  const handleResendOtp = useCallback(() => {
    setLoading(true)
    axiosInstance
      .post(ApiEndPoints.AUTH.resendOtp, { email })
      .then(response => response.data)
      .then(response => {
        toastSuccess(response.message)
      })
      .catch(error => {
        toastError(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [email])

  return (
    <>
      <Dialog
        open={open}
        onClose={toggle}
        maxWidth='sm'
        fullWidth
        sx={{
          '& .MuiDialog-paper': {
            backgroundColor: 'transparent',
            borderRadius: '30px',
            overflow: 'hidden'
          }
        }}
      >
        <Grid container sx={{ overflowY: { xs: 'auto', xl: 'hidden' } }}>
          <Grid
            item
            sx={{
              display: 'flex',
              alignItems: 'center',
              backgroundColor: '#FFFFFF',
              p: { md: 10, xs: '24px 12px' },
              width: '100%'
            }}
          >
            <Container>
              <Box display={'flex'} gap={2} flexDirection={'column'} textAlign={'center'} paddingBottom={'20px'}>
                <TitleTypography>Please Enter OTP</TitleTypography>
                <Typography>An OTP has been sent to your email ({email}) to set your password.</Typography>
              </Box>
              <Box>
                <Box display={'flex'} flexDirection={'column'} gap={'20px'}>
                  <form noValidate autoComplete='off' onSubmit={handleSubmit(handleOtpSubmit)}>
                    <FormControl fullWidth>
                      <FormLabel htmlFor='otp'>Please enter the OTP here</FormLabel>
                      <Controller
                        name='otp'
                        control={control}
                        render={({ field: { value, onChange } }) => (
                          <TextField
                            autoFocus
                            id='otp'
                            placeholder='Enter OTP'
                            value={value}
                            onChange={onChange}
                            error={!!errors.otp}
                            helperText={errors.otp ? errors.otp.message : ''}
                          />
                        )}
                      />
                    </FormControl>
                    <Box mt={2}>
                      <Typography
                        variant='body2'
                        sx={{ textDecoration: 'underline', cursor: 'pointer', textAlign: 'right' }}
                        onClick={handleResendOtp}
                      >
                        Resend OTP
                      </Typography>
                    </Box>
                    <Box display={'flex'} gap={6} mt={5}>
                      <Button
                        onClick={toggle}
                        variant='outlined'
                        fullWidth
                        sx={{ padding: '14px 18px', border: '1px solid #0D1E32', fontSize: '18px', color: '#0D1E32' }}
                      >
                        Cancel
                      </Button>
                      <LoadingButton
                        type='submit'
                        variant='contained'
                        fullWidth
                        loading={loading}
                        sx={{ padding: '14px 18px', border: '1px solid #0D1E32', fontSize: '18px' }}
                      >
                        Next
                      </LoadingButton>
                    </Box>
                  </form>
                </Box>
              </Box>
            </Container>
          </Grid>
        </Grid>
      </Dialog>
    </>
  )
}

export default OtpDialog
