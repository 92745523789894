import { useState, useCallback } from 'react'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import FormLabel from '@mui/material/FormLabel'
import { styled } from '@mui/material/styles'
import useCustomTranslation from 'src/@core/hooks/useCustomTranslation'
import { useForm, Controller } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { axiosInstance } from 'src/network/adapter'
import { ApiEndPoints } from 'src/network/endpoints'
import { toastError, toastSuccess } from 'src/utils/utils'
import { LoadingButton } from '@mui/lab'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined'
import { IconButton, InputAdornment } from '@mui/material'

const TitleTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontSize: '32px',
  lineHeight: '44.8px',
  fontWeight: 700,
  textAlign: 'center'
}))

const validationSchema = yup.object().shape({
  newPassword: yup.string().required('New password is required').min(6, 'Password must be at least 6 characters long'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('newPassword'), null], 'Passwords must match')
    .required('Confirm password is required')
})

function ResetPassword({ open, toggle, email }) {
  const [loading, setLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const translation = useCustomTranslation()

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange'
  })

  const handleResetPassword = useCallback(
    data => {
      setLoading(true)
      axiosInstance
        .post(ApiEndPoints.AUTH.resetPassword, { email, newPassword: data.newPassword })
        .then(response => response.data)
        .then(response => {
          toastSuccess(response.message)
          toggle()
          reset()
        })
        .catch(error => {
          toastError(error)
        })
        .finally(() => {
          setLoading(false)
        })
    },
    [email, toggle]
  )

  const handleClickShowPassword = () => {
    setShowPassword(prev => !prev)
  }

  return (
    <Dialog
      open={open}
      onClose={toggle}
      maxWidth='sm'
      fullWidth
      sx={{
        '& .MuiDialog-paper': {
          backgroundColor: 'transparent',
          borderRadius: '30px',
          overflow: 'hidden'
        }
      }}
    >
      <Grid container sx={{ overflowY: { xs: 'auto', xl: 'hidden' } }}>
        <Grid
          item
          sx={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#FFFFFF',
            p: { md: 10, xs: '24px 12px' },
            width: '100%'
          }}
        >
          <Container>
            <Box display='flex' flexDirection='column' textAlign='center' gap={2} paddingBottom={'20px'}>
              <TitleTypography>Reset Your Password</TitleTypography>
              <Typography>The password should have at least 6 characters.</Typography>
            </Box>
            <Box display='flex' flexDirection='column' gap='20px'>
              <form noValidate autoComplete='off' onSubmit={handleSubmit(handleResetPassword)}>
                <FormControl fullWidth sx={{ mb: 4 }}>
                  <FormLabel htmlFor='newPassword' sx={{ color: '#253547', fontSize: '16px' }}>
                    New Password
                  </FormLabel>
                  <Controller
                    name='newPassword'
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <TextField
                        id='newPassword'
                        placeholder='New Password'
                        type={showPassword ? 'text' : 'password'}
                        value={value}
                        onChange={onChange}
                        error={!!errors.newPassword}
                        helperText={errors.newPassword ? errors.newPassword.message : ''}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position='end'>
                              <IconButton
                                aria-label='toggle password visibility'
                                onClick={handleClickShowPassword}
                                edge='end'
                              >
                                {showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                    )}
                  />
                </FormControl>
                <FormControl fullWidth sx={{ mb: 4 }}>
                  <FormLabel htmlFor='confirmPassword' sx={{ color: '#253547', fontSize: '16px' }}>
                    Confirm Password
                  </FormLabel>
                  <Controller
                    name='confirmPassword'
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <TextField
                        id='confirmPassword'
                        placeholder='Confirm Password'
                        type={showPassword ? 'text' : 'password'}
                        value={value}
                        onChange={onChange}
                        error={!!errors.confirmPassword}
                        helperText={errors.confirmPassword ? errors.confirmPassword.message : ''}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position='end'>
                              <IconButton
                                aria-label='toggle password visibility'
                                onClick={handleClickShowPassword}
                                edge='end'
                              >
                                {showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                    )}
                  />
                </FormControl>
                <LoadingButton
                  type='submit'
                  variant='contained'
                  fullWidth
                  loading={loading}
                  sx={{ padding: '14px 18px', border: '1px solid #0D1E32', fontSize: '18px', mt: 3 }}
                >
                  Reset Password
                </LoadingButton>
              </form>
            </Box>
          </Container>
        </Grid>
      </Grid>
    </Dialog>
  )
}

export default ResetPassword
