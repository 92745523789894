import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import ListItem from '@mui/material/ListItem'
import Typography from '@mui/material/Typography'
import ListItemText from '@mui/material/ListItemText'
import { styled } from '@mui/material/styles'
import CruzLogo from 'src/@core/components/icons/CruzLogo'
import { Link, useNavigate } from 'react-router-dom'
const BoxWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  backgroundColor: '#FFFFFF',
  paddingInline: '100px',
  [theme.breakpoints.down('sm')]: {
    paddingInline: '20px'
  },
  paddingTop: '50px'
}))

const TitleTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontSize: 24,
  lineHeight: '33.6px',
  fontWeight: 700
}))

const SubFooterBoxWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    gap: 20
  },
  paddingBlock: '20px'
}))

const SubFooterText = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontSize: 14,
  lineHeight: '20px',
  fontWeight: 400
}))

const AboutContent = [
  { title: 'About Us', to: '/about' },
  { title: 'Careers', to: '/career-page' },
  { title: 'Terms of Use', to: '/terms&conditions' },
  { title: 'Privacy Policy', to: '/privacy-policy' },
  { title: 'Cookies Policy', to: '/cookie-policy' },
  { title: 'Contact Us', to: '/contact' }
]
const ExperiencesContent = [
  { title: 'Boat Rentals' },
  { title: 'Jet Ski Rental' },
  { title: 'Fishing Charters' },
  { title: 'Houseboat Rental' },
  { title: 'Pontoon Rental' },
  { title: 'Yacht Rental' },
  { title: 'Sailboat Rental' }
]
const PopularDestinationsContent = [
  { title: 'Company News' },
  { title: 'Lifestyle' },
  { title: 'Miami' },
  { title: 'Lake Lanier' },
  { title: 'Lake Tahoe' },
  { title: 'San Diego' },
  { title: 'Lake Travis' },
  { title: 'Newport Beach' },
  { title: 'Chicago' }
]
const LiveSupportContent = [
  { title: 'Help & FAQs' },
  { title: 'contact@cruizewatersport.com' },
  { title: 'support@cruizewatersport.com' }
]

function LandingPageFooter() {
  const navigate = useNavigate()

  return (
    <>
      <BoxWrapper>
        <Grid container direction='row' justifyContent='space-between' alignItems='flex-start' spacing={5}>
          <Grid item xs={12} sm={6} md={3}>
            <TitleTypography>About</TitleTypography>
            <List>
              <ListItem sx={{ display: 'contents' }}>
                {AboutContent.map((item, index) => {
                  return (
                    <Link to={item?.to} style={{ textDecoration: 'none' }}>
                      <ListItemText
                        key={index}
                        primary={item.title}
                        primaryTypographyProps={{
                          sx: {
                            color: 'secondary.main',
                            fontSize: 15,
                            fontWeight: 400
                          }
                        }}
                      />
                    </Link>
                  )
                })}
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TitleTypography>Experiences</TitleTypography>
            <List>
              <ListItem sx={{ display: 'contents' }}>
                {ExperiencesContent.map((item, index) => {
                  return (
                    <ListItemText
                      key={index}
                      primary={item.title}
                      primaryTypographyProps={{
                        sx: {
                          color: 'secondary.main',
                          fontSize: 15,
                          fontWeight: 400
                        }
                      }}
                    />
                  )
                })}
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TitleTypography>Popular Destinations</TitleTypography>
            <List>
              <ListItem sx={{ display: 'contents' }}>
                {PopularDestinationsContent.map((item, index) => {
                  return (
                    <ListItemText
                      key={index}
                      primary={item.title}
                      primaryTypographyProps={{
                        sx: {
                          color: 'secondary.main',
                          fontSize: 15,
                          fontWeight: 400
                        }
                      }}
                    />
                  )
                })}
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TitleTypography>24/7 Live Support</TitleTypography>
            <List>
              <ListItem sx={{ display: 'contents' }}>
                {LiveSupportContent.map((item, index) => {
                  return (
                    <ListItemText
                      key={index}
                      primary={item.title}
                      primaryTypographyProps={{
                        sx: {
                          color: 'secondary.main',
                          fontSize: 15,
                          fontWeight: 400
                        }
                      }}
                    />
                  )
                })}
              </ListItem>
            </List>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <SubFooterBoxWrapper>
            <Box display={'flex'} alignItems={'center'} gap={3}>
              <CruzLogo height={'50'} width={'130'} fill={'#0D1E32'} />
              <SubFooterText>© Cruizewatersport 2024</SubFooterText>
            </Box>
            <Box display={'flex'} justifyContent={'center'} alignItems={'center'} gap={3}>
              <SubFooterText>Terms & Conditions</SubFooterText>
              <SubFooterText>Privacy Policy</SubFooterText>
            </Box>
          </SubFooterBoxWrapper>
        </Grid>
      </BoxWrapper>
    </>
  )
}
export default LandingPageFooter
